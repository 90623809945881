import React from "react"
import Calendar from "../Calendar/Calendar"

export default function Home() {
  return (
    <div>
      {/* <div
        style={{
          padding: "20px",
          borderRadius: "5px",
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "18px",
          fontFamily: "Arial, Helvetica, sans-serif",
          maxWidth: "50%",
          margin: "0 auto",
        }}
      >
        Now through December 31, get one month FREE with no obligation when you
        join The Broadway Maven. For just $18 a month, Broadway Maven members
        get 5-15 classes and other expert-led educational experiences every
        month, plus access to the Clips Archive and occasional drawings for
        Broadway and off-Broadway tickets including select opening nights.
      </div>
      <br />
      <br />

      <a
        href="https://thebroadwaymaven.substack.com/subscribe?coupon=9c4717f1&utm_content=153234540"
        target="_blank"
        rel="noreferrer"
        style={{
          display: "block",
          fontSize: "24px",
          textDecoration: "underline",
          hover: "hand",
          color: "#7756e3",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        FREE One-Month Membership
      </a> */}
      {/* <Link to="/all-access">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Broadway Maven ALL-ACCESS Pass</div>
            </Link>

            <br />
            {/* <Link to="/studentseminar">
              <div
                style={{
                  fontSize: "24px",
                  textDecoration: "underline",
                  hover: "hand",
                  color: "#7756e3",
                }}
              >Apply for the Student Seminar</div>
            </Link> */}
      <br />
      <br />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Calendar />
      </div>
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <iframe
          title="David Benkof Video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/eEOaGfEjCYM?si=ygMwvNTaJwHbZUxe?autoplay=1"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <br />

      <div
        style={{
          padding: "20px",
          borderRadius: "5px",
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "18px",
          fontFamily: "Arial, Helvetica, sans-serif",
          maxWidth: "50%",
          margin: "0 auto",
        }}
      >
        The Broadway Maven is an educational community that helps its members think more deeply about musical theater. Every month, members may attend 5-15 expert-led classes and innovative Broadway experiences, all for just $18. We also foster enthusiasm for Broadway through the FREE weekly substack newsletter <a href="https://thebroadwaymaven.substack.com/" target="_blank" rel="noreferrer" style={{ color: "#007bff", textDecoration: "none" }}>MARQUEE</a> and host an expansive <a href="https://www.youtube.com/c/DavidBenkofTheBroadwayMaven" target="_blank" rel="noreferrer" style={{ color: "#007bff", textDecoration: "none" }}>YouTube channel</a>. It's your home for Broadway appreciation.
      </div>
      <br />
    </div>
  )
}
